<template>
  <div class="main-box">
    <div class="card-box" v-if="listLen">
<!--      <div class="check-all">-->
<!--        <el-checkbox label="全选" :model="checkedAll" border @click.native="checkAll($event,checkedAll)"></el-checkbox>-->
<!--      </div>-->
      <div class="out-box">
        <div class="video-box" v-for="(item,index) in list">
          <span class="check">
            <el-checkbox label="" v-model="checkItem[index]" name="type" @click.native="handleSelectionChange($event,item,index)"></el-checkbox>
          </span>
          <div class="img-box">
<!--            <el-image-->
<!--              style="width: 200px; height: 220px"-->
<!--              fit="contain"-->
<!--              :src="item.visit_photo">-->
<!--            </el-image>-->
            <swipe :list="item" :index="index" @clickImg="clickImg"/>
          </div>
<!--          <div class="hover-box" @click="clickImg(item,index,$event)">-->
<!--            <div class="bottom-btn">-->
<!--            </div>-->
<!--          </div>-->

          <div class="video-name">
            <el-tooltip class="item" effect="light" placement="top" popper-class="b-tooltip">
              <div slot="content" style="line-height: 20px;padding: 0 10px;">
                <span>
                  <span class="info-span">姓名：</span>{{item.name}}<br>
                  <span class="info-span">年龄：</span>{{item.age }}<br>
                  <span class="info-span">类别：</span>{{item.type == 0? '科班' : '非科班'}}<br>
                  <span class="info-span">国籍：</span>{{item.nationality}}<br>
                </span>
              </div>
              <div class="actor-info">
                <span><a href="javascript:;">{{item.name}}</a></span>
                <span><a href="javascript:;">{{item.age+'岁'}}</a></span>
                <div class="comment">
                  <a>点评：</a>{{item.comment? item.comment : '暂无'}}
                </div>
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="no-data" v-else>
      <no-data/>
    </div>
    <el-dialog
      title="素材分享"
      :visible.sync="dialogVisible"
      width="30%">
      <div class="tips">
        <i class="el-icon-success" style="font-size: 50px;color: #70d413"></i><br>
        <span style="font-size: 20px">链接复制成功</span>
        <span>链接已复制到剪切板，可直接粘贴分享</span>
        <span> </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import NoData from '@/components/NoData/NoData'
  import  swipe from '@/components/Swipe'
  export default {
    name: "videoCard",
    props: ['list','listLen'],
    components: { NoData, swipe },
    data() {
      return {
        checkItem: [],
        checkItems: [],
        checkedAll: false,
        dialogVisible: false
      }
    },
    watch: {
      list(val) {
        this.checkItem = []
        for ( let i =0; i < this.list.length; i++ ) {
          this.checkItem.push(false)
        }
      }
    },
    created() {

    },
    methods: {
      clickImg(row,index,event) {
        // let event1 = event.currentTarget
        // let event2 = event.target
        // if (event1 == event2) {
        //   this.$emit('clickImg',row,index)
        // }
        this.$emit('clickImg',row,index)
      },
      showPutAdvertisers(row) {
        this.$emit('showPutAdvertisers',row)
      },
      handleSelectionChange(e,val,index) {
        if (e.target.tagName === 'INPUT') return
        // this.checkItem.push(val)
        if (!this.checkItem[index]) {
          this.checkItems.push(val)
        }else {
          let index = this.checkItems.findIndex( item => item.id == val.id);
          this.checkItems.splice(index,1)
        }
        this.$emit('handleSelectionChange',this.checkItems)
      },
      checkAll(e,checkedAll) {
        if (e.target.tagName === 'INPUT') return;
        this.checkedAll =  !this.checkedAll
        for (let i =0; i < this.list.length; i++) {
          this.$set( this.checkItem, i, !checkedAll )
        }
        if (!checkedAll) {
          for (let i of this.list) {
            this.checkItems.push(i)
          }
          this.$emit('handleSelectionChange',this.checkItems)
        }else {
          this.checkItems = []
          this.$emit('handleSelectionChange',this.checkItems)
        }
      },
      toDetail() {
        this.$router.push({
          path: 'compete/data_detail/'+ '123'
        })
      },
      share(item) {
        const input = document.createElement('input')
        document.body.appendChild(input)
        input.setAttribute('value', item.visit_path)
        input.select()
        if (document.execCommand('copy')) {
          document.execCommand('copy')
          this.dialogVisible = true
        }
        document.body.removeChild(input)
      },
    }
  }
</script>

<style scoped>
  .check-all {
    margin-top: -40px;
    padding-left: 0;
  }
  .out-box {
    max-width: 100%;
    margin: 20px auto;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
  }
  .video-box {
    width: 200px;
    height: 270px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    margin: 10px;
    flex: 0 0 200px;
    background: #d9d9d9;
    box-shadow: 0 2px 3px #bdbdbd;
    cursor: pointer;
  }
  .video-box:hover{
    /*box-shadow: 0 0 10px rgb(64, 158, 255);*/
  }
  .img-box {
    width: 200px;
    height: 220px;
    /*display: flex;*/
    /*align-items: center;*/
  }
  .video-name {
    width: 180px;
    height: auto;
    /*padding: 5px;*/
    position: absolute;
    bottom: 0;
    background: white;
    text-align: center;
    padding: 12px 10px;
  }
  .video-name span {
    display: block;
    padding: 4px 5px;
  }
  .actor-info {
    display: flex;
    height: 64px;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .actor-info span {
    font-weight: 500;
  }
  .check {
    position: absolute;
    top: 20px;
    left: 170px;
    z-index: 99;
  }
  .playbtn {
    width: 50px;
    height: 50px;
    position: absolute;
    margin-left: 50%;
    left: -25px;
    margin-top: 50%;
    top: -25px;
    z-index: 10;
  }
  a, a:focus, a:hover {
    color: black !important;
    font-weight: bolder;
    text-decoration: none;
  }
  .no-data {
    width: 100%;
    margin-top: 100px;
  }
  .hover-box {
    width: 200px;
    height: 220px;
    top: 0;
    z-index: 20;
    opacity: 1;
    padding-top: 170px;
    position: absolute;
    background: #20a0ff;
  }
  /*.hover-box:hover {*/
  /*  opacity: 1;*/
  /*  background: rgba(0, 0, 0, 0.29)*/
  /*}*/
  .bottom-btn {
    width: 100%;
    height: 30px;
    position: absolute;
    top: 180px;
    /*background: #ffa400;*/
    display: flex;
    justify-content: flex-end;
    z-index: 2;
  }
  .detail {
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 35px;
    margin-right: 5px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }
  .detail img {
    width: 50%;
    height: 50%;
  }
  .tips {
    text-align: center;
  }
  .tips span {
    display: block;
    margin-top: 20px;
  }
  .comment {
    width: 200px;
    height: 40px;
    padding: 5px 5px;
    line-height: 20px;
    font-size: 12px;
    text-align: left;
    color: #373737;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
</style>
