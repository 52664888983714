<template>
  <div class="box">
    <div id="dataCenter">
      <div class="window" @mouseover="stop" @mouseleave="play" @click="clickImg">
        <ul class="container" :style="containerStyle">
          <li>
            <!-- 把最后一张图放在最开始 -->
            <img :src="list.photo_list[list.photo_list.length - 1].url" alt="">
          </li>
          <li  v-for="(item, index) in list.photo_list" :key="index">
            <img :src="item.url" alt="">
          </li>
          <li>
            <!-- 把第一张图放在最后 -->
            <img :src="list.photo_list[0].url" alt="">
          </li>
        </ul>
        <div class="change-box">
          <!--        // 左右切换按钮-->
          <ul class="direction">
            <li class="left" @click.stop="move(imgWidth, 1, speed)">
              <svg class="icon" width="15px" height="15px" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#ffffff" d="M481.233 904c8.189 0 16.379-3.124 22.628-9.372 12.496-12.497 12.496-32.759 0-45.256L166.488 512l337.373-337.373c12.496-12.497 12.496-32.758 0-45.255-12.498-12.497-32.758-12.497-45.256 0l-360 360c-12.496 12.497-12.496 32.758 0 45.255l360 360c6.249 6.249 14.439 9.373 22.628 9.373z"  /></svg>
            </li>
            <li class="right" @click.stop="move(imgWidth, -1, speed)">
              <svg class="icon" width="15px" height="15px" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#ffffff" d="M557.179 904c-8.189 0-16.379-3.124-22.628-9.372-12.496-12.497-12.496-32.759 0-45.256L871.924 512 534.551 174.627c-12.496-12.497-12.496-32.758 0-45.255 12.498-12.497 32.758-12.497 45.256 0l360 360c12.496 12.497 12.496 32.758 0 45.255l-360 360c-6.249 6.249-14.439 9.373-22.628 9.373z"  /></svg>
            </li>
          </ul>
          <!--        // 小圆点-->
          <ul class="dots">
            <li v-for="(dot, i) in list.photo_list" :key="i"
                :class="{dotted: i === (currentIndex-1)}"
                @click.stop = jump(i+1)>
            </li>
          </ul>
        </div>
      </div>
<!--      // 点击图片预览-->
      <div class="mask-div" @click="maskFun()" v-if="maskBol">
        <img :style="{width:imgWidth+'px'}" :src="list.photo_list[imgNumber].img" alt="" class="mask-img">
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'dataCenter',
    props: [ 'list', 'index' ],
    data () {
      return {
        imgWidth: 200, // 图片宽度
        currentIndex: 1, // 原点起始位置
        distance: -200, // 外层嵌套的初始移动距离
        transitionEnd: true, // 防止多次快速点击切换出现问题的闸门
        speed: this.initialSpeed,
        timer: null, // 定时器
        imgNumber: 0, // 点击放大的图片
        maskBol: false
      }
    },
    computed: {
      containerStyle () {
        return {
          transform: `translate3d(${this.distance}px, 0, 0)`
        }
      },
      interval () {
        return this.initialInterval * 3000
      }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        this.play()
      },
      move (offset, direction, speed) { // 移动一次的距离， 向左还是向右移动， 图片移动速度
        if (!this.transitionEnd) return
        this.transitionEnd = false
        direction === -1 ? this.currentIndex += offset / this.imgWidth : this.currentIndex -= offset / this.imgWidth
        if (this.currentIndex > this.list.photo_list.length) this.currentIndex = 1
        if (this.currentIndex < 1) this.currentIndex = this.list.photo_list.length

        const destination = this.distance + offset * direction
        this.animate(destination, direction, speed)
      },
      animate (des, direc, speed) { // 实际移动距离 想左还是向右 移动速度 负右正左
        if (this.temp) {
          window.clearInterval(this.temp)
          this.temp = null
        }
        this.temp = window.setInterval(() => {
          if ((direc === -1 && des < this.distance) || (direc === 1 && des > this.distance)) {
            this.distance += speed * direc
          } else {
            this.transitionEnd = true
            window.clearInterval(this.temp)
            this.distance = des
            let allWidth = this.list.photo_list.length * this.imgWidth
            if (des < -allWidth) this.distance = -this.imgWidth
            if (des > -this.imgWidth) this.distance = -allWidth
          }
        }, 10)
      },
      jump (index) {
        const direction = index - this.currentIndex >= 0 ? -1 : 1
        const offset = Math.abs(index - this.currentIndex) * this.imgWidth
        const jumpSpeed = Math.abs(index - this.currentIndex) === 0 ? this.speed : Math.abs(index - this.currentIndex) * this.speed
        this.move(offset, direction, jumpSpeed)
      },
      // 自动播放函数
      play () {
        // if (!this.maskBol) {
        //   if (this.timer) {
        //     window.clearInterval(this.timer)
        //     this.timer = null
        //   }
        //   this.timer = window.setInterval(() => {
        //     this.move(this.imgWidth, -1, this.speed)
        //   }, this.interval)
        // }
      },
      stop () {
        // window.clearInterval(this.timer)
        // this.timer = null
      },
      amplification (index) {
        this.imgNumber = index
        this.maskBol = true
        this.stop()
      },
      maskFun () {
        this.maskBol = false
        this.play()
      },
      clickImg(e) {
        let type = typeof this.index
        if (type != "number") return
        this.$emit('clickImg', this.list, this.index, e)
      }
    }
  }
</script>
<style lang="scss" scoped>
  *{
    box-sizing: border-box;
    margin:0;
    padding:0;
  }
  ol,ul{
    list-style: none;
  }
  .box {
    width: 200px;
    height: 181px;
    position: relative;
  }
  .box img {
    width: 200px;
    height: 181px;
    object-fit: contain;
  }
  .box:hover .change-box {
    opacity: 1;
  }
  .change-box {
    opacity: 0;
  }
  #dataCenter{
    text-align: center;
    .window{
      position:relative;
      width: 100%;
      height: 181px;
      margin:0 auto;
      overflow:hidden;
      .container{
        display:flex;
        position:absolute;
      }
      .left, .right{
        position:absolute;
        top:50%;
        width:20px;
        height:20px;
        background-color:rgba(0,0,0,.3);
        border-radius:50%;
        cursor:pointer;
      }
      .left{
        left:3%;
        padding-left:5px;
        padding-top:2px;
      }
      .right{
        right:3%;
        padding-right:5px;
        padding-top:2px;
      }
      img{
        user-select: none;
      }
    }
    .dots{
      position:absolute;
      bottom: 0;
      left:50%;
      transform:translateX(-50%);
    }
    .dots li{
      display:inline-block;
      width:10px;
      height:10px;
      margin:0 3px;
      border:1px solid white;
      border-radius:50%;
      background-color:#333;
      cursor:pointer;
    }
    .dots .dotted{
      background-color:orange;
    }
    .mask-div {
      width: 100vw;
      height: 100vh;
      background-color: rgba(0,0,0,.7);
      position: fixed;
      z-index: 999;
      top: 0;
      left: 0;
      .mask-img{
        height: 100%;
      }
    }
  }
</style>
