<template>
  <div class="box">
    <img src="./img/null.png" alt="">
    <div class="tips">暂时没有数据</div>
  </div>
</template>

<script>
  export default {
    name: "NoData"
  }
</script>

<style scoped>
  .box {
    width: 230px;
    height: auto;
    margin: 0 auto;
    font-size: 16px;
  }
  .box img {
    width: 230px;
    height: 150px;
  }
  .tips {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
  }
</style>
