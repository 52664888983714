<template>
  <div>
    <div class="out-box">
      <div class="filter-container">
        <div class="filter-container-left">
          <div>
            <el-button v-if="listTotal > 0"  class="filter-item" type="success" plain>
              总数据: {{listTotal}}
            </el-button>
          </div>
        </div>
        <div class="filter-container-right">
          <div class="switch">
            <div class="change-btn change-btn1" :class="{ 'actived': !actived}"  @click="changeActive(0)">
              <i class="el-icon-postcard"></i>
              卡片模式
            </div>
            <div class="change-btn change-btn2" :class="{ 'actived': actived}"  @click="changeActive(1)">
              <i class="el-icon-c-scale-to-original"></i>
              列表模式
            </div>
          </div>
        </div>
      </div>
      <actor-list
        v-if="actived"
        :list="list"
        :cooperate_num = "listQuery.cooperate_num"
        @handleSelectionChange="handleSelectionChange"
        @clickImg="clickImg"/>
      <actor-card
        v-if="!actived"
        :list="list"
        :listLen="listLen"
        @handleSelectionChange="handleSelectionChange"
        @clickImg="clickImg"
      />
      <div class="page-center">
        <el-pagination
          class="page-content"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="listQuery.page"
          :page-sizes="[5, 10, 20, 50]"
          :page-size="listQuery.rows"
          :total="listTotal"
          hide-on-single-page
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>
    </div>

    <el-drawer
      :with-header="false"
      :visible.sync="dialogVisible" size="950px">
      <actorInfo
        :dialogVisible="dialogVisible"
        :videoInfo="videoInfo"/>
    </el-drawer>

<!--    <el-tooltip placement="top" content="回到顶部">-->
<!--      <back-to-top :visibility-height="300" :back-position="0" transition-name="fade"/>-->
<!--    </el-tooltip>-->
  </div>
</template>

<style scoped>
  .out-box {
    width: 95%;
    margin: 0 auto;
    box-shadow: 0 0 5px rgba(224, 224, 224, 0.6);
    padding: 12px 20px;
    background: white;
    font-size: 12px;
  }
  .page-center{
    text-align: center;
  }
  .page-cotent{
    margin: 20px;
  }
  .switch {
    display: flex;
    margin-left: 10px;
    cursor: pointer;
  }
  .change-btn {
    display: inline-block;
    padding: 0 20px;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }
  .change-btn:hover {
    background: rgba(64, 158, 255, 0.2);
    color: black;
  }
  .change-btn1{
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-top: 1px solid #DCDFE6;
    border-left: 1px solid #DCDFE6;
    border-bottom: 1px solid #DCDFE6;
  }
  .change-btn2{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: 1px solid #DCDFE6;
    border-right: 1px solid #DCDFE6;
    border-bottom: 1px solid #DCDFE6;
  }
  .actived {
    /*background: #409EFF;*/
    background: #ffab29;
    color: white;
  }
</style>
<style scoped>
  .filter-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    flex-wrap: wrap;
  }
  .filter-container-left {
    display: flex;
    justify-content:space-between;
    margin: 10px 0 0 0;
  }
  .filter-container-right {
    margin: 10px 0 0 0;
  }
</style>

<script>
  import { getList, del, getFiled, shareKey } from "@/api/actor";
  // import  BackToTop from '@/components/BackToTop'
  import actorList from './components/actorList'
  import actorCard from './components/actorCard'
  import actorInfo from './components/actorInfo'
  // import { backTo, downloadFile, copy } from "@/helper/helper";
  import axios from "axios";
  // import { getToken } from '@/utils/auth'

  export default {
    components: { actorList, actorCard, actorInfo },
    data() {
      return {
        list: null,
        isPause: false,
        listLen: false,
        listTotal: 0,
        isSelf: '全部上传',
        fileList: [],
        listQuery: {
          page: 1,
          rows: 20,
          type: '',
          query_type: '',
          keyword: '',
          age_start: '',
          age_end: '',
          cooperate_num: ''
        },
        dialogVisible: false,
        multipleSelection: [],
        batchDown: true,
        deleteVisible: false,
        refNamePopover: 'popover-',
        actived: false,
        videoInfo: '',
        card_index: 0,
        errorImg: 'this.src = "https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1595494150326&di=e70ac002400985cc7d562722e9aa0b73&imgtype=0&src=http%3A%2F%2Fpic.soutu123.cn%2Felement_origin_min_pic%2F01%2F37%2F92%2F40573c69065b76e.jpg%2521%2Ffw%2F700%2Fquality%2F90%2Funsharp%2Ftrue%2Fcompress%2Ftrue"',
        delLoading: false,
        batch_id: '',
        batchs: [
          { id: 0, name: '批量下载' },
          // { id: 1, name: '批量分享' }
        ],
        typeOptions: [
          { key: '0', name: '非科班' },
          { key: '1', name: '科班' }
        ],
        options: [
          { type: 'name', name: '姓名' },
          { type: 'wechat', name: '微信' },
          { type: 'mobile', name: '手机号'},
          { type: 'brand', name: '合作品牌' },
          { type: 'nationality', name: '国籍' },
          { type: 'comment', name: '评价'}
        ],
        searchAge: '',
        initAges: [
          { key: '[10, 20]', value: '10-20' },
          { key: '[21, 30]', value: '21-30' },
          { key: '[31, 40]', value: '31-40' },
          { key: '[41, 50]', value: '41-50' },
          { key: '[51, 60]', value: '51-60' },
        ],
        ages: [],
        filedObj: {},
        importDialog: false,
        url: process.env.VUE_APP_BASE_API+'/admin/actor/import',
      }
    },
    watch: {
      $route(val) {
        this.initData()
      },
      multipleSelection(val) {
        this.batchDown = val.length > 0 ? false : true
      },
      searchAge(val) {
        if(val) {
          let ages = JSON.parse(val)
          this.listQuery.age_start = ages[0]
          this.listQuery.age_end = ages[1]
        }else {
          this.ages = this.initAges
          this.listQuery.age_start = this.listQuery.age_end = ''
        }
      }
    },
    computed: {
      batchLen() {
        return this.multipleSelection.length
      },
      is_super() {
        return localStorage.getItem('is_super') == 1? true : false
      }
    },
    created() {
      // let query = JSON.parse(JSON.stringify(this.$route.query))
      // this.listQuery.page = query.page? Number(query.page) : 1
      this.fetchData()
    },
    mounted() {
    },
    beforeDestroy() {
      if (this.player) {
        this.player.dispose()
      }
    },
    methods: {
      fetchData() {
        this.ages = [ ...this.initAges ]
        let key = { key: this.$route.query.shareID }
        getList(key).then(response => {
          this.list = response.data.data
          this.listLen = this.list.length > 0
          this.listTotal = response.data.total
          this.listQuery.page = response.data.current_page
          this.listQuery.rows = response.data.per_page
          this.actived = false
        })
      },
      // 初始页currentPage、初始每页数据数pagesize和数据data
      handleSizeChange(size) {
        this.listQuery.rows = size
        this.sizeChange()
      },
      handleCurrentChange(currentPage) {
        this.listQuery.page = currentPage
        this.sizeChange()
      },
      sizeChange() {
        backTo()
        // this.$router.push({
        //   query: {
        //     page: this.listQuery.page
        //   }
        // })
        getList(this.listQuery).then(response => {
          this.list = response.data.data
          this.listLen = this.list.length > 0
          this.listTotal = response.data.total
        })
      },
      handleFilter(column) {
        this.listQuery.page = 1
        getList(this.listQuery).then(response => {
          this.list = response.data.data
          this.listLen = this.list.length > 0
          this.listTotal = response.data.total
          this.listQuery.page = response.data.current_page
          this.listQuery.rows = response.data.per_page
        })
      },
      handleDelete(row, index, is_list) {
        this.delLoading = true
        del({id: row.id}).then(response => {
          this.delLoading = false
          let idx = index? index : this.card_index
          this.list.splice(idx, 1)
          this.$message({
            message: response.msg,
            type: 'success'
          });
          this.dialogVisible = false
        }).catch(err =>{
          this.delLoading = false
          this.dialogVisible = false
        });
      },
      clickImg(row,index) {
        this.videoInfo = row
        this.card_index = index
        this.dialogVisible = true
      },
      download(row,type) {
        let path = process.env.VUE_APP_BASE_API
          + '/admin/template_download/download?index=ActorImport'
          + '&token='
          + getToken()
        this.$message.success('下载请求发送成功！')
        downloadFile(path)
      },
      batchChoose() {
        if(this.batch_id === 0) {
          this.download(this.multipleSelection,1)
        }else if(this.batch_id === 1){
          // this.batchShare()
        }else {
          // this.batchPutAdvertisers()
        }
      },
      //多选处理
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      //隐藏弹窗
      hidePopover(row) {
        let refName = this.refNamePopover + row.id;
        this.$refs[refName].doClose();
      },
      //更改列表或卡片显示
      changeActive(val) {
        this.actived = val
      },
      //关闭抽屉
      closeDrawer() {
        this.dialogVisible = false
      },
      reset() {
        this.listQuery = {
          page: 1,
          rows: 20,
          type: '',
          name: '',
          brand: '',
          wechat: '',
          mobile: '',
          nationality: '',
          start_time: '',
          end_time: ''
        }
        this.handleFilter()
      },
      addAges(e) {
        let value = e.target.value
        if(value && /^\d{1,2}-\d{1,2}/.test(value)) {
          let arr = value.split('-');
          this.ages = [{ key: `[${arr}]`, value: value}]
        }else {
          this.$message.error('输入格式有误');
        }
      },
    }
  }
</script>
