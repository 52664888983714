<template>
  <div style="padding: 20px" ref="click">
    <el-table
      :data="list"
      border fit
      highlight-current-row
      ref="table">

      <el-table-column
        align="center"
        type="selection"
        width="55">
      </el-table-column>

      <el-table-column label="素材" align="center" width="160">
        <template slot-scope="scope">
          <div class="list-card">
            <el-image
              style="width: 140px; height: 140px; border-radius: 5px;"
              @click="clickImg(scope.row,scope.$index)"
              fit="contain"
              :src="scope.row.photo_list[0].url">
            </el-image>
            <div class="sid-box">{{ scope.row.name }}</div>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="姓名" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>

      <el-table-column label="年龄" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.age + '岁' }}</span>
        </template>
      </el-table-column>

      <el-table-column label="类别" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.type == 0? '科班' : '非科班' }}</span>
        </template>
      </el-table-column>


      <el-table-column label="合作品牌" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.brand}}</span>
        </template>
      </el-table-column>

      <el-table-column label="点评" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.comment }}</span>
        </template>
      </el-table-column>

      <el-table-column label="合作次数" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.cooperate_num }}</span>
        </template>
      </el-table-column>

      <el-table-column label="微信号" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.wechat }}</span>
        </template>
      </el-table-column>

      <el-table-column label="手机号" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.mobile }}</span>
        </template>
      </el-table-column>

<!--      <el-table-column label="备注" align="center" show-overflow-tooltip>-->
<!--        <template slot-scope="scope">-->
<!--          <span>{{ scope.row.remark }}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->

      <el-table-column label="创建时间" align="center">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span>{{ scope.row.create_time }}</span>
        </template>
      </el-table-column>

    </el-table>
  </div>
</template>

<script>
  export default {
    name: "videoList",
    props: ['list', 'cooperate_num'],
    data() {
      return {
        refNamePopover: 'popover-',
        passwordType: 'password',
      }
    },
    methods: {
      clickImg(row,index) {
        this.$emit('clickImg',row,index)
      },
    }
  }
</script>

<style scoped>
  .table-btn{
    margin-bottom: 8px;
  }
  .list-card {
    width: 140px;
    height: 140px;
    margin: 0 auto;
    background: white;
    border: 1px rgba(192, 192, 192, 0.47) solid;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .tag {
    position:absolute;
    z-index: 99;
    top: 0;
    left: 0;
    width: 50px;
    height: 25px;
    font-size: 10px;
    text-align: center;
    line-height: 25px;
    border-radius: 0 0 5px 0;
    cursor: pointer;
  }
  .tag span{
    font-size: 10px;
    color: white;
  }
  .sid-box {
    width: 100%;
    height: auto;
    max-height: 50px;
    font-size: 8px;
    padding: 5px 5px;
    text-align: center;
    /*padding: 0 5px;*/
    word-wrap: break-word;
    line-height: 15px !important;
    /*padding: 10px !important;*/
    color: rgb(255, 255, 255);
    position: absolute;
    background: rgba(96, 96, 96, 0.51);
    bottom: 0;
    left: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    cursor: pointer;
  }
</style>
