<template>
  <div class="info">
    <div class="head-box">
      <div class="name">
        {{videoInfo.name}}
      </div>
    </div>
    <el-divider content-position="left">个人信息</el-divider>
    <div class="video-box">
      <div class="swipe-box">
        <swipe v-if="dialogVisible" :list="videoInfo"/>
      </div>
      <div class="video-info" :videoInfo="videoInfo">
        <div class="info-flex">
          <div>
            <span class="span">出生日期</span>
            <span>{{videoInfo.birthday}}</span>
          </div>
          <div>
            <span class="span">类别</span>
            <span>{{videoInfo.type == 0? '科班' : '非科班'}}</span>
          </div>
          <div>
            <span class="span">微信号</span>
            <span>{{videoInfo.wechat}}</span>
            <span class="show-pwd"  @click="showFiled(videoInfo,'wechat')" v-if="videoInfo.wechat">
              <svg-icon icon-class="eye-open" />
            </span>
          </div>
          <div>
            <span class="span">手机号</span>
            <span>{{videoInfo.mobile}}</span>
            <span class="show-pwd"  @click="showFiled(videoInfo,'mobile')" v-if="videoInfo.mobile">
              <svg-icon icon-class="eye-open" />
            </span>
          </div>
          <div>
            <span class="span">合作品牌</span>
            <span>{{videoInfo.brand}}</span>
          </div>
          <div>
            <span class="span">合作次数</span>
            <span>{{videoInfo.cooperate_num + '次'}}</span>
          </div>
          <div class="comment">
            <span class="span">点评</span><br><br>
            <span>{{videoInfo.comment? videoInfo.comment : '暂无'}}</span>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="title">-->
<!--      作品列表-->
<!--      <el-divider></el-divider>-->
<!--    </div>-->
    <el-divider content-position="left">作品列表</el-divider>
    <div class="works_list" v-if="dialogVisible">
      <div class="player" v-for="item in videoInfo.works_list">
        <video controls class="video-js vjs-default-skin" ref="video" v-if="item.type == 1">
          <source :src="item.visit_path" type="video/mp4">
          您的浏览器不支持视频播放
        </video>
        <el-image
          v-if="item.type == 0"
          :src="item.visit_path"
          fit="contain"
          class="img">
        </el-image>
      </div>
    </div>
  </div>
</template>

<script>
  import  swipe from '@/components/Swipe'
  export default {
    props: [ 'videoInfo', 'dialogVisible' ],
    components: { swipe },
    name: "actorInfo",
    data() {
      return {
        refNamePopover: 'popover-',
        delLoading: false,
      }
    },
    watch: {

    },
    methods: {

    }
  }
</script>

<style scoped>
  .info {
    width: 900px;
    margin: 20px auto;
    padding: 10px 10px;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px silver;
    font-size: 14px;
  }
  .video-box{
    position: relative;
    display: flex;
    justify-content: space-between;
  }
  .video-info {
    width: 100%;
    padding: 0 10px;
    margin-left: 10px;
    position: relative;
  }
  .video-info p {
    font-weight: bold;
  }
  .info-flex {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .info-flex div {
    flex: 0 300px;
    padding: 15px 10px;
  }
  .name {
    width: 200px;
    padding: 10px 10px !important;
    color: black !important;
    font-size: 18px !important;
    font-weight: bold !important;
  }
  .comment {
    flex: 0 800px !important;
  }
  .span {
    display: inline-block;
    color: #9d9d9d;
    width: 90px;
  }
  .works_list {
    width: 848px;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
  }
  .player {
    flex: 0 400px;
    height: 250px;
    margin-left: 16px;
    margin-bottom: 16px;
    position: relative;
  }
  .player video{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
  .player .img {
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 5px;
    border: 1px solid #777777;
  }
  .head-box {
    display: flex;
    justify-content: space-between;
  }
  .put-box {
    display: flex;
    height: 30px;
    justify-content: flex-end;
    margin-top: 5px;
  }
  .swipe-box {
    flex: 0 200px;
    height: 181px;
    border-radius: 5px;
    box-shadow: 0 0 2px silver;
    background: #d9d9d9;
  }
</style>
